import { NextSeo } from 'next-seo'
import _ from 'lodash'

const baseSOE = {
   title: 'GlobalXtreme Internet Service Provider | Fiber Broadband, Wireless, TV & Phone',
   description:
      'GlobalXtreme Internet Service Provider | Fiber Broadband, Wireless, TV & Phone | ISP Indonesia | ISP Bali | ISP Malang | ISP Balikpapan | ISP Samarinda',
}

export const SeoPageGeneral = ({
   canonical,
   isAddManually = false,
   configSeo = {},
   configOpenGraph = {},
   locale = 'en',
}) => {
   // console.log('configOpenGraph: ', configOpenGraph)
   let dataSeo = { ...baseSOE, ...configSeo }

   let openGraph = {
      ...baseSOE,
      url: `https://globalxtreme.net${canonical}`,
      type: 'wibsite',
      locale: locale,
      siteName: `https://globalxtreme.net${canonical}`,
      images: [
         {
            alt: 'GlobalXtreme Internet Service Provider | Fiber Broadband, Wireless, TV & Phone | ISP Bali | ISP Malang | ISP Balikpapan | ISP Samarinda | Fiber Broadband, Wireless, TV & Phone',
            url: 'https://globalxtreme.net/images/ogimage.jpg',
            width: '1200',
            height: '630',
         },
      ],
      ...configOpenGraph,
      canonical: `https://globalxtreme.net${canonical}`,
   }

   const metaTags = [
      {
         property: 'og:locale:alternate',
         content: locale,
      },
      {
         name: 'google-site-verification',
         content: '',
      },
      {
         name: 'author',
         content: 'Fastest Internet Connection in Bali',
      },
   ]
   return (
      <NextSeo
         canonical={`https://globalxtreme.net${canonical}`}
         additionalMetaTags={metaTags}
         twitter={{
            site: '@GlobalXtreme',
            handle: '@GlobalXtreme',
            cardType: 'summary',
         }}
         openGraph={openGraph}
         {...dataSeo}
      />
   )
}
